<template>
    <layout-full-width :title="$tc('merchant', 2)">
        <component-actions :action-filters="[]"
                           :itemsPerPage="10"
                           :page="1"
                           :primary-actions="primaryActions"
                           :total="1"
                           @applyFilters="onApplyFilters"
                           @changeItemsPerPage="onChangeItemsPerPage"
                           @paginate="onChangePage"
                           @search="onSearch"/>

        <div class="row">
            <div class="col-12">
                <div class="table-responsive">
                    <table :class="{'table-hover': !loading.listMerchants}" class="table">
                        <thead>
                        <tr>
                            <th scope="col">{{ $tc('name', 1) }}</th>
                            <th scope="col">{{ $tc('vat-id', 1) }}</th>
                            <th scope="col">{{ $tc('status', 1) }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-cloak v-if="!loading.listMerchants && profile !== null">
                            <tr>
                                <td>{{ profile.company }}</td>
                                <td>{{ profile.vatId }}</td>
                                <td><span class="badge bg-primary">{{ $t('active') }}</span></td>
                            </tr>
                        </template>
                        <template v-cloak v-if="!loading.listMerchants && profile === null">
                            <tr>
                                <td colspan="99">
                                    <div
                                        class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                        role="alert">
                                        <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                        <div class="mt-2 fs-4">{{ $t('pages.merchants.no-merchants-found') }}</div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template v-if="loading.listMerchants">
                            <tr>
                                <td colspan="99">
                                    <component-spinner class="py-4" color="text-primary" type="spinner-grow"/>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </layout-full-width>
</template>

<script>
import LayoutFullWidth from "@/layouts/LayoutFullWidth";
import ComponentActions from '@/components/ui/ComponentActions.vue';
import ComponentSpinner from "@/components/ui/ComponentSpinner";

export default {
    name: "PageMerchants",
    components: {ComponentSpinner, LayoutFullWidth, ComponentActions},
    computed: {
        profile() {
            return this.$store.state.USER.profile
        }
    },
    data() {
        return {
            loading: {
                listMerchants: true
            },
            primaryActions: [
                {
                    text: this.$t('actions.new'),
                    icon: 'plus',
                    type: 'modal',
                    disabled: true,
                    target: ''
                }
            ]
        }
    },
    methods: {
        onChangePage() {
            console.log('onChangePage');
        },
        onSearch() {
            console.log('onSearch');
        },
        onChangeItemsPerPage() {
            console.log('onChangeItemsPerPage');
        },
        onApplyFilters() {
            console.log('onApplyFilters');
        },
    },
    async beforeMount() {
        await this.$store.dispatch('USER/GET_PROFILE');
        this.loading.listMerchants = false;
    }
}
</script>

<style lang="scss" scoped>
</style>
